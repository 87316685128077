import React from "react"
// import ServicesForm from "components/Forms/ServicesForm"

import {
  SectionTitle,
  ServiceContainer,
  ServiceCard,
  CardHeader,
  CardBody,
  FolderIcon,
  CardContainer,
  Container,
} from "./ServicesSection.styles"

const ServicesSection = ({ title, cards }) => {
  return (
    <Container>
      <ServiceContainer>
        <SectionTitle>{title}</SectionTitle>
        <CardContainer>
          {cards.map(({ id, title, description }) => (
            <ServiceCard key={id}>
              <CardHeader>
                <FolderIcon />
                <h3>{title}</h3>
              </CardHeader>
              <CardBody>
                <p>{description}</p>
              </CardBody>
            </ServiceCard>
          ))}
        </CardContainer>
      </ServiceContainer>
    </Container>
  )
}

export default ServicesSection
