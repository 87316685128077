import styled from "styled-components"
import { AiFillFolderOpen } from "react-icons/ai"

export const Container = styled.div`
  background-color: #fafafa;
`
export const ServiceContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 0;
`
export const SectionTitle = styled.h2`
  margin-top: 64px;
  margin-bottom: 48px;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 769px) {
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0 0 14px;
  text-transform: uppercase;
`

export const FolderIcon = styled(AiFillFolderOpen)`
  font-size: 32px;
  margin-right: 16px;
`

export const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  align-self: center;
  margin-bottom: 24px;

  & + div {
    margin-bottom: 24px;
  }

  @media screen and (min-width: 769px) {
    flex: 0 1 45%;
    min-height: 256px;
    padding: 16px;

    & + div {
      margin-bottom: 36px;
    }
  }
`

export const CardBody = styled.div`
  padding: 15px 14px 10px 14px;
  text-align: justify;
  line-height: 32px;

  @media screen and (max-width: 375px) {
    line-height: 24px;
  }
  @media screen and (min-width: 768px) {
    line-height: 32px;
  }
`
