import { graphql, useStaticQuery } from "gatsby"

export const useServicePageDataQuery = () => {
  const data = useStaticQuery(graphql`
    query ServiceDataQuery {
      mdx(frontmatter: { type: { eq: "servicePage" } }) {
        frontmatter {
          heroContent {
            backgroundImage {
              pluginImage {
                childImageSharp {
                  gatsbyImageData(
                    quality: 90
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    formats: AUTO
                  )
                }
              }
              alt
            }
            subTitle
            title
          }
        }
      }
    }
  `)
  return data.mdx.frontmatter
}
