import React from "react"
import Seo from "components/seo"
import HeroSection from "components/HeroSection"
import ServicesSection from "components/ServicesSection"

import { useIndexPageDataQuery } from "hooks/useIndexPageDataQuery"
import { useServicePageDataQuery } from "hooks/useServicePageDataQuery"

const Index = () => {
  const { heroContent } = useServicePageDataQuery()
  const { serviceSectionContent } = useIndexPageDataQuery()
  return (
    <>
      <Seo title="Serviços" />
      <HeroSection {...heroContent} />
      <ServicesSection {...serviceSectionContent} />
    </>
  )
}

export default Index
