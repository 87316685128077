import { useStaticQuery, graphql } from "gatsby"

export const useIndexPageDataQuery = () => {
  const data = useStaticQuery(graphql`
    query IndexPageDataQuery {
      mdx(frontmatter: { type: { eq: "indexPage" } }) {
        frontmatter {
          heroContent {
            subTitle
            title
            backgroundImage {
              alt
              pluginImage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: AUTO
                    quality: 90
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          formSectionContent {
            title
            subtitle
            formTitle
            btn1Text
            btn2Text
          }
          serviceSectionContent {
            title
            cards {
              id
              description
              title
            }
          }
          ourMissionSectionContent {
            title
            description
            sideImage {
              alt
              pluginImage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: AUTO
                    quality: 90
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          whereWeAreSectionContent {
            title
            description
            btnText
            backgroundImage {
              alt
              pluginImage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: AUTO
                    quality: 90
                    layout: FIXED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  return data.mdx.frontmatter
}
